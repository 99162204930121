import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
import ScrollToTop from "../components/ScrollToTop";
import Page404 from "../pages/auth/Page404";
import SignIn from "../pages/auth/SignIn";
import routes from './dashboard';
import * as routeDefinitions from './routeDefinitions'
import DashboardPage from "../pages/DashboardPage";

const Routes = () => {
    return <Router>
        <ScrollToTop>
            <Switch>
                <Route path={routeDefinitions.ROUTE_SIGN_IN} render={() => (<AuthLayout><SignIn/></AuthLayout>)}/>
                {routes.map(r => <Route path={r.route} exact render={() => (<DashboardLayout>{r.render()}</DashboardLayout>)} key={r.route}/>)}
                <Route path={routeDefinitions.ROUTE_DASHBOARD} render={() => (<DashboardLayout><DashboardPage/></DashboardLayout>)}/>
                <Route render={() => <AuthLayout><Page404/></AuthLayout>}/>
            </Switch>
        </ScrollToTop>
    </Router>
}

export default Routes;
