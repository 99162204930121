export const BUSINESS_LIST_REQUEST = 'BUSINESS_LIST_REQUEST';
export const BUSINESS_LIST_SUCCESS = 'BUSINESS_LIST_SUCCESS';
export const BUSINESS_UPDATE_SUCCESS = 'BUSINESS_UPDATE_SUCCESS';
export const BUSINESS_DETAILS_REQUEST = 'BUSINESS_DETAILS_REQUEST';
export const BUSINESS_DETAILS_SUCCESS = 'BUSINESS_DETAILS_SUCCESS';
export const BUSINESS_PARTNER_LIST_REQUEST = 'BUSINESS_PARTNER_LIST_REQUEST';
export const BUSINESS_PARTNER_LIST_SUCCESS = 'BUSINESS_PARTNER_LIST_SUCCESS';

var initialState = {
    byId: [],
    allIds: [],
    loading: true,
    loadingPartners: true
}

export default (state = initialState, action) => {
    if (action.payload?.entities?.business) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.business
            }
        }
    }
    switch (action.type) {
        case BUSINESS_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case BUSINESS_LIST_SUCCESS: {
            return {
                ...state,
                allIds: action.payload.result,
                loading: false,
                pagination: action.payload.meta
            }
        }
        case BUSINESS_DETAILS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case BUSINESS_DETAILS_SUCCESS: {
            return {
                ...state,
                loading: false
            }
        }
        case BUSINESS_PARTNER_LIST_REQUEST: {
            return {
                ...state,
                loadingPartners: true
            }
        }
        case BUSINESS_PARTNER_LIST_SUCCESS: {
            return {
                ...state,
                loadingPartners: false
            }
        }
        default:
            return state;
    }
}