export const CONTACT_REQUEST_LIST_REQUEST = 'CONTACT_REQUEST_LIST_REQUEST';
export const CONTACT_REQUEST_LIST_SUCCESS = 'CONTACT_REQUEST_LIST_SUCCESS';

var initialState = {
    byId: [],
    allIds: [],
    loading: true,
}

export default (state = initialState, action) => {
    if (action.payload?.entities?.contactRequest) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.contactRequest
            }
        }
    }
    switch (action.type) {
        case CONTACT_REQUEST_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case CONTACT_REQUEST_LIST_SUCCESS: {
            return {
                ...state,
                allIds: action.payload.result,
                loading: false,
                pagination: action.payload.meta
            }
        }
        default:
            return state;
    }
}