export const GALLERY_EDITOR_INIT = 'GALLERY_EDITOR_INIT';
export const GALLERY_EDITOR_SET_IMAGES = 'GALLERY_EDITOR_SET_IMAGES';
export const GALLERY_EDITOR_RESET = 'GALLERY_EDITOR_RESET';
export const GALLERY_EDITOR_ADD = 'GALLERY_EDITOR_ADD';
export const GALLERY_EDITOR_DELETE = 'GALLERY_EDITOR_DELETE';
export const GALLERY_EDITOR_SAVING = 'GALLERY_EDITOR_SAVING';
export const GALLERY_EDITOR_SAVED = 'GALLERY_EDITOR_SAVED';
var initialState = {
    loading: false,
    images: [],
    formChanged: false,
    saving: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GALLERY_EDITOR_RESET: {
            return {
                ...state,
                loading: true,
                images: []
            }
        }
        case GALLERY_EDITOR_INIT: {
            return {
                ...state,
                images: action.payload.entities.image ? Object.keys(action.payload.entities.image) : [],
                loading: false
            }
        }
        case GALLERY_EDITOR_SET_IMAGES: {
            return {
                ...state,
                images: action.payload,
                formChanged: true
            }
        }
        case GALLERY_EDITOR_ADD: {
            return {
                ...state,
                images: [
                    ...state.images,
                    action.payload
                ]
            }
        }
        case GALLERY_EDITOR_DELETE: {
            return {
                ...state,
                images: [
                    ...state.images.filter(i => i !== action.payload)
                ]
            }
        }
        case GALLERY_EDITOR_SAVING: {
            return {
                ...state,
                saving: true
            }
        }
        case GALLERY_EDITOR_SAVED: {
            return {
                ...state,
                saving: false,
                formChanged: false
            }
        }
        default:
            return state;
    }
}