import {SET_LOGGED_USER, LOGOUT} from "../constants/actiontypes/auth";

let initialState = {
    logged_user: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LOGGED_USER:
            return {
                ...state,
                logged_user: action.payload.result
            }
        case LOGOUT:
            return {
                ...state,
                logged_user: null
            }
        default:
            return state;
    }
};