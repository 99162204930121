import React from "react";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer";
import {useLocation, withRouter} from "react-router-dom";
import {Redirect} from 'react-router-dom'
import {ROUTE_DASHBOARD, ROUTE_SIGN_IN} from "../routes/routeDefinitions";
import {useSelector} from 'react-redux';
import routes from "../routes";
import {hasPermissionTo} from "../core/utils/appUtil";
import {getLoggedUser} from "../selectors";

let Dashboard = ({headerText, children}) => {

    let loggedUser = useSelector(getLoggedUser)

    if (!loggedUser) {
        return <Redirect to={ROUTE_SIGN_IN}/>
    }
    const location = useLocation();
    let hasPermission = true;
    routes.forEach((category, index) => {
        if(location.pathname.startsWith(category.path) && category.permission && !hasPermissionTo(category.permission, loggedUser)) {
            hasPermission = false
        }
        if (category.children) {
            category.children.forEach((route, index) => {
                if(location.pathname.startsWith(route.path) && route.permission && !hasPermissionTo(route.permission, loggedUser)) {
                    hasPermission = false
                }
            })
        }
    })

    if(!hasPermission) {
        return <Redirect to={ROUTE_DASHBOARD}/>
    }

    return <div className='wrapper'>
        <Sidebar/>
        <Main>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <Navbar headerText={headerText}/>
                <div className="content">{children}</div>
                <Footer/>
            </div>
        </Main>
    </div>


}

export default withRouter(Dashboard)
