import React from "react";
import {Row, Col} from 'reactstrap'
import BusinessDetailsForm from "../../../components/forms/app/business/BusinessDetailsForm";
import BusinessPage from "./BusinessPage";
import {fetchBusiness} from "../../../actions/business";
import {useDispatch} from "react-redux";
import {useRouteMatch} from "react-router-dom";

let BusinessDetailsPage = () => {

    let match = useRouteMatch();

    let businessId = match.params.id

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchBusiness(businessId))
    }, [businessId, dispatch])

    return <BusinessPage businessId={businessId}>
        <Row className='fitheight'>
            <Col lg={{size: 6, offset: 3}}>
                <BusinessDetailsForm businessId={businessId}/>
            </Col>
        </Row>
    </BusinessPage>

}

export default BusinessDetailsPage
