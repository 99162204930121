import React from "react";
import {useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import routes from "../../routes/index";
import SidebarCategory from "./SidebarCategory";
import SidebarItem from "./SidebarItem";
import {hasPermissionTo} from "../../core/utils/appUtil";
import {trans} from "../../core/utils/translationUtil";
import {GiHamburgerMenu} from 'react-icons/gi';
import {FaSearch} from 'react-icons/fa';
import {toggleSidebar} from "../../actions/sidebar";
import logo from "../../assets/img/logo_white.svg";
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {ROUTE_DASHBOARD} from "../../routes/routeDefinitions";
import {getLoggedUser} from "../../selectors";

let Sidebar = () => {

    let sidebar = useSelector(state => state.sidebar);

    let globalState = useSelector(state => state)

    let loggedUser = useSelector(getLoggedUser);

    let intl = useIntl();

    let dispatch = useDispatch();

    let history = useHistory();

    const location = useLocation();

    let toggleMenu = () => dispatch(toggleSidebar());

    let [currentCategory, setCurrentCategory] = useState('');

    let [openedCategory, setOpenedCategory] = useState('');

    let currentPath = location.pathname;

    React.useEffect(() => {
        setCurrentCategory(null);
        routes.forEach((category, index) => {
            if (category.children) {
                category.children.forEach((route, index) => {
                    if(currentPath === ROUTE_DASHBOARD) {
                        if (currentPath === route.path) {
                            setCurrentCategory(category.name);
                            setOpenedCategory(category.name);
                        }
                    } else {
                        if (currentPath.startsWith(route.path)) {
                            setCurrentCategory(category.name);
                            setOpenedCategory(category.name);
                        }
                    }
                });
            }
        });
    }, [currentPath])

    let openCategory = (category = null, isOpen = true) => {
        if (!category) {
            setOpenedCategory(null);
        } else if (isOpen) {
            setOpenedCategory(category.name);
        } else {
            setOpenedCategory(null);
        }
    }

    return <nav className={"sidebar" + (!sidebar.isOpen ? " toggled" : "")}>
        <div className="sidebar-content">
            <PerfectScrollbar>
                <div className='sidebar-header'>
                    <img alt='' src={logo} onClick={() => history.push(ROUTE_DASHBOARD)} style={{height: '30px', marginTop: '5px'}}/>
                    <div className='hamburger' onClick={toggleMenu}>
                        <GiHamburgerMenu size={19}/>
                    </div>
                </div>
                <div className='sidebar-search'>
                    <input type='text' name='search' placeholder='Szukaj' autoComplete='off'/>
                    <FaSearch size={16}/>
                </div>
                <div className="sidebar-nav">
                    {routes.map((category, index) => {
                        if (category.permission && !hasPermissionTo(category.permission, loggedUser)) {
                            return false;
                        }
                        if(category.extraCondition && !category.extraCondition(globalState)) {
                            return false;
                        }
                        return (
                            <React.Fragment key={index}>

                                {category.children ? (
                                    <SidebarCategory
                                        name={trans(intl, category.name)}
                                        icon={category.icon}
                                        to={category.path}
                                        isActive={currentCategory === category.name}
                                        isOpen={openedCategory === category.name || currentCategory === category.name}
                                        onClick={() => openCategory(category, openedCategory !== category.name)}
                                    >
                                        {category.children.map((route, index) => {
                                            if (route.permission && !hasPermissionTo(route.permission, loggedUser)) {
                                                return false;
                                            }
                                            if(route.extraCondition && !route.extraCondition(globalState)) {
                                                return false;
                                            }
                                            return <div
                                                className={'sidebar-list-item ' + (currentPath === route.path ? 'active' : '')}
                                                key={index}>
                                                <Link to={route.path}>{trans(intl, route.name)}</Link></div>
                                        })}
                                    </SidebarCategory>
                                ) : (
                                    <SidebarItem
                                        name={trans(intl, category.name)}
                                        to={category.path}
                                        icon={category.icon}
                                        unexpandMenu={() => openCategory()}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            </PerfectScrollbar>
        </div>
    </nav>

}

export default Sidebar
