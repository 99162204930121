import {createAction} from "../core/utils/appUtil";
import api from '../core/api';
import {normalize} from 'normalizr';
import schemas from "../reducers/schemas";
import handleError from "../core/utils/errorUtil";
import {
    BUSINESS_DETAILS_REQUEST,
    BUSINESS_DETAILS_SUCCESS,
    BUSINESS_LIST_REQUEST,
    BUSINESS_LIST_SUCCESS, BUSINESS_PARTNER_LIST_REQUEST, BUSINESS_PARTNER_LIST_SUCCESS
} from "../reducers/business";
import {getPartners} from "../core/api/business";

export function fetchBusinesses() {
    return function (dispatch) {
        dispatch(createAction(BUSINESS_LIST_REQUEST));
        return api.business.getAll().then(function (response) {
            let normalizedData = normalize(response.data, [schemas.businessSchema]);
            normalizedData.meta = response.data.meta
            dispatch(createAction(BUSINESS_LIST_SUCCESS, normalizedData));
        })
            .catch(error => handleError(error, dispatch));
    };
}

export function fetchBusiness(businessId) {
    return function (dispatch) {
        dispatch(createAction(BUSINESS_DETAILS_REQUEST));
        return api.business.get(businessId).then(function (response) {
            let normalizedData = normalize(response.data.data, schemas.businessSchema);
            dispatch(createAction(BUSINESS_DETAILS_SUCCESS, normalizedData));
        })
            .catch(error => handleError(error, dispatch));
    };
}

export function fetchBusinessPartners(businessId) {
    return function (dispatch) {
        dispatch(createAction(BUSINESS_PARTNER_LIST_REQUEST));
        return api.business.getPartners(businessId).then(function (response) {
            let normalizedData = normalize(response.data, [schemas.partnerSchema]);
            normalizedData.business_id = businessId
            dispatch(createAction(BUSINESS_PARTNER_LIST_SUCCESS, normalizedData));
        })
            .catch(error => handleError(error, dispatch));
    };
}