import React from "react";
import TableSkeleton from "../skeleton/TableSkeleton";
import DatatablePagination from "./DatatablePagination";
import TextField from '@mui/material/TextField';
import FormWrapper from "../../form/FormWrapper";

const Datatable = ({
                       columnHeaders,
                       data,
                       rowNumbers = true,
                       loading = false,
                       extraClasses = '',
                       loaderRows = 3,
                       loaderCols = null,
                       pagination = null,
                       onChange = null,
                       search = false
                   }) => {
    if (!onChange) {
        onChange = () => {
        }
    }
    let columnCount = columnHeaders.length + (rowNumbers ? 1 : 0);

    let [searchText, setSearchText] = React.useState('');

    let [perPage, setPerPage] = React.useState(10);

    let [page, setPage] = React.useState(pagination?.current_page);

    React.useEffect(() => {
        onChange(page, perPage, searchText)
    }, [page, perPage, searchText])

    return <div className='datatable'>
        {search && <FormWrapper>
            <TextField
                label='Szukaj'
                type='text'
                value={searchText}
                onChange={(e) => {
                    setSearchText(e.target.value)
                }}/>
        </FormWrapper>}
        <table className={'table ' + extraClasses}>
            <thead>
            <tr>
                {rowNumbers && <th className='counter-column'/>}
                {
                    columnHeaders.map(header => <th key={header}>{header}</th>)
                }
            </tr>
            </thead>
            <tbody>
            {loading && <TableSkeleton cols={loaderCols ? loaderCols : columnCount} rows={loaderRows}/>}
            {
                !loading && data.length === 0 && [1].map(i => <tr key={i}>
                    <td colSpan={columnCount} align='center' key={i}>
                        <span>Brak</span>
                    </td>
                </tr>)
            }
            {
                !loading && data.map((row, key) => <tr key={key}>
                    {rowNumbers && <td className='counter-column'>{key + 1}</td>}
                    {
                        row.map(d => d)
                    }
                </tr>)
            }
            {
                pagination && <tr>
                    <td colSpan={columnCount}>
                        <DatatablePagination
                            currentPage={pagination?.current_page}
                            lastPage={pagination?.last_page}
                            onChange={onChange}
                            page={page}
                            setPage={setPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </td>
                </tr>
            }
            </tbody>
        </table>
    </div>
}

export default Datatable;
