import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from '@redux-devtools/extension';
import reducer from './reducer';
import thunk from 'redux-thunk';
import jwt_decode from "jwt-decode";
import {getJwtToken, setJwtToken} from "./actions/auth";
import moment from 'moment';
import * as api from "./core/api";

const refreshTokenMiddleware = store => next => action => {
    if (typeof action === 'function') {
        let token = getJwtToken()
        if (token) {
            try {
                let data = jwt_decode(getJwtToken())
                let exp = data.exp;
                let now = moment().unix();
                let difference = exp - now;
                if (difference < 60 * 10) {
                    api.refreshToken()
                        .then((response) => {
                            let data = response.data.data;
                            setJwtToken(data.access_token);
                        })
                }
            } catch (e) {
                return next(action)
            }
        }

    }
    return next(action)
}

export const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(refreshTokenMiddleware, thunk))
);
window.store = store;