import {schema} from 'normalizr';
const resourceTypeSchema = new schema.Entity('resourceType');
const resourceSchema = new schema.Entity('resource');
const notificationSchema = new schema.Entity('notification');
const partnerSchema = new schema.Entity('partner');
const clientSchema = new schema.Entity('client');
const facilitySchema = new schema.Entity('facility');
const serviceSchema = new schema.Entity('service');
const reservationSchema = new schema.Entity('reservation');
const facilityTypeSchema = new schema.Entity('facilityType')
const openingHourSchema = new schema.Entity('openingHour');
const inavailabilityHourSchema = new schema.Entity('inavailabilityHour');
const paymentSchema = new schema.Entity('payment');
const paymentTypeSchema = new schema.Entity('paymentType');
const addressSchema = new schema.Entity('address');
const businessSchema = new schema.Entity('business');
const imageSchema = new schema.Entity('image');
const roleSchema = new schema.Entity('role');
const permissionSchema = new schema.Entity('permission');
const languageSchema = new schema.Entity('language');
const adminSchema = new schema.Entity('admin');
const contactRequestSchema = new schema.Entity('contactRequest');

resourceSchema.define({
    type: resourceTypeSchema,
    facilities: [facilitySchema],
    openingHours: [openingHourSchema],
    services: [serviceSchema],
});
paymentSchema.define({
    paymentType: paymentTypeSchema
});
facilityTypeSchema.define({
    resourceTypes: resourceTypeSchema
});
facilitySchema.define({
    type: facilityTypeSchema,
    address: addressSchema,
    openingHours: [openingHourSchema],
    services: [serviceSchema],
    resources: [resourceSchema],
    partners: [partnerSchema],
    inavailability_hours: [inavailabilityHourSchema],
    images: [imageSchema]
});
serviceSchema.define({
    facilities: [facilitySchema],
    resourceTypes: [resourceTypeSchema],
    partners: [partnerSchema],
    resources: [resourceSchema],
});
partnerSchema.define({
    openingHours: [openingHourSchema],
    services: [serviceSchema],
    facilities: [facilitySchema],
    inavailability_hours: [inavailabilityHourSchema],
    business: businessSchema,
    roles: [roleSchema],
    language: languageSchema
});
reservationSchema.define({
    partner: partnerSchema,
    client: clientSchema,
    service: serviceSchema,
    facility: facilitySchema,
    partners: [partnerSchema],
    payments: [paymentSchema],
    resources: [resourceSchema]
});
roleSchema.define({
    permissions: [permissionSchema]
});
facilityTypeSchema.define({
    resource_types: [resourceTypeSchema]
});

export default {
    adminSchema,
    businessSchema,
    resourceTypeSchema,
    resourceSchema,
    notificationSchema,
    partnerSchema,
    clientSchema,
    facilitySchema,
    addressSchema,
    serviceSchema,
    reservationSchema,
    facilityTypeSchema,
    openingHourSchema,
    inavailabilityHourSchema,
    paymentSchema,
    paymentTypeSchema,
    imageSchema,
    roleSchema,
    permissionSchema,
    languageSchema,
    contactRequestSchema
};