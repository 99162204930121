import {createAction} from "../core/utils/appUtil";
import api from "../core/api";
import {toastSuccess} from "../core/utils/appUtil";
import schemas from "../reducers/schemas";
import {normalize} from 'normalizr';
import {
    PARTNER_DETAILS_REQUEST,
    PARTNER_DETAILS_SUCCESS,
    PARTNER_LIST_REQUEST,
    PARTNER_LIST_SUCCESS
} from "../reducers/partner";
import handleError from "../core/utils/errorUtil";
import {OPENING_HOUR_FORM_INIT, OPENING_HOUR_FORM_RESET} from "../reducers/openingHourForm";

export function fetchAllPartners(filters = {}, includes = []) {
    return function (dispatch) {
        dispatch(createAction(PARTNER_LIST_REQUEST));
        return api.getPartners(filters, includes)
            .then((response) => {
                dispatch(createAction(PARTNER_LIST_SUCCESS, normalize(response.data, [schemas.partnerSchema])));
            })
            .catch(error => handleError(error, dispatch));
    };
}

export function fetchPartnerDetails(partnerId, includes = []) {
    return function (dispatch) {
        dispatch(createAction(PARTNER_DETAILS_REQUEST));
        return api.getPartner(partnerId, includes)
            .then(response => dispatch(createAction(PARTNER_DETAILS_SUCCESS, normalize(response.data, schemas.partnerSchema))))
            .catch(error => handleError(error, dispatch))
    };
}


export function openPartnerOpeningHoursPage(id) {
    return function (dispatch) {
        dispatch(createAction(OPENING_HOUR_FORM_RESET));
        return api.getPartner(id, ['openingHours'])
            .then(response => {
                let data = normalize(response.data, schemas.partnerSchema)
                dispatch(createAction(OPENING_HOUR_FORM_INIT, data));
            })
            .catch(error => handleError(error, dispatch));
    };
}

export function sendAccountConfirmationMessage(partnerId, onSuccess = () => {
}) {
    return function (dispatch) {
        return api.createAccountInvitation(partnerId).then((response) => {
            toastSuccess('Wysłano zaproszenie do aplikacji')
            onSuccess()
        }).catch(error => handleError(error, dispatch));
    };
}

export function loginAsPartner(partnerId) {
    return function (dispatch) {
        return api.partner.loginAs(partnerId).then(function (response) {
            let data = response.data;
            if (data.access_token) {
                window.open(
                    process.env.REACT_APP_PARTNERS_PANEL_URL + '/loginas/' + partnerId + '/' + data.access_token,
                    '_blank'
                );
            }
        }).catch(error => handleError(error, dispatch));
    };
}