import React from "react";
import TextInput from "../../../core/form/TextInput";

let BusinessInstagramField = ({control, defaultValue, loading}) => {

    return <TextInput
        loading={loading}
        name='instagram'
        control={control}
        defaultValue={defaultValue}
        label='form.business.instagram'
    />

}

export default BusinessInstagramField