import {createAction} from "../core/utils/appUtil";
import api from '../core/api';
import {normalize} from 'normalizr';
import schemas from "../reducers/schemas";
import handleError from "../core/utils/errorUtil";
import {
    BUSINESS_DETAILS_REQUEST,
    BUSINESS_DETAILS_SUCCESS,
    BUSINESS_LIST_REQUEST,
    BUSINESS_LIST_SUCCESS, BUSINESS_PARTNER_LIST_REQUEST, BUSINESS_PARTNER_LIST_SUCCESS
} from "../reducers/business";
import {getPartners} from "../core/api/business";
import {ADMIN_LIST_REQUEST, ADMIN_LIST_SUCCESS} from "../reducers/admin";

export function fetchAdmins() {
    return function (dispatch) {
        dispatch(createAction(ADMIN_LIST_REQUEST));
        return api.admin.getAll().then(function (response) {
            let normalizedData = normalize(response.data, [schemas.adminSchema]);
            normalizedData.meta = response.data.meta
            dispatch(createAction(ADMIN_LIST_SUCCESS, normalizedData));
        })
            .catch(error => handleError(error, dispatch));
    };
}