import {createPostRequest, createPutRequest, getApi, makeRequest} from "../utils/apiUtil";

export function login(email, password, type = 'client') {
    return getApi().post('auth/login', {
        email: email,
        password: password,
        type: type
    })
}
export function getAccount() {
    return getApi().get('auth/account');
}

export function loginClient(email, password) {
    return login(email, password, 'client')
}

export function loginPartner(email, password) {
    return login(email, password, 'partner')
}

export function loginAdmin(email, password) {
    return login(email, password, 'admin')
}

export function register(formData, type = 'client') {
    formData.type = type
    return getApi().post('auth/register', formData)
}

export function registerClient(formData) {
    return register(formData, 'client')
}

export function registerPartner(formData) {
    return register(formData, 'partner')
}

export function resetPasswordLink(formData) {
    return makeRequest(createPostRequest('auth/resetPassword'), formData);
}
export function resetPassword(id, formData) {
    return makeRequest(createPutRequest('auth/resetPassword/:id'), formData, {id: id});
}
export function confirmAccount(formData) {
    return getApi().post('account/confirm', formData);
}