import React from "react";
import {Link, withRouter} from "react-router-dom";
import {ROUTE_DASHBOARD} from "../../routes/routeDefinitions";

const SidebarItem = withRouter(
    ({name, icon: Icon, location, to}) => {
        const getSidebarItemClass = path => {
            let clazz = '';
            if(location.pathname === ROUTE_DASHBOARD) {
                clazz = location.pathname === path ? 'active' : '';
            } else {
                clazz = (path !== ROUTE_DASHBOARD && location.pathname.startsWith(path)) ? 'active' : ''
            }
            return clazz;
        };

        return (
            <div className={"sidebar-item " + getSidebarItemClass(to)}>
                <Link className='sidebar-item-header' to={to}>
                    {Icon ? <Icon size={16}/> : null}
                    <div className='sidebar-text'>
                        {name}
                    </div>
                </Link>
            </div>
        );
    }
);

export default SidebarItem;