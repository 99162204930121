import React from "react";
import SwitchInput from "../../../../core/form/SwitchInput";

let BusinessTrialModeField = (props) => {

    return <SwitchInput
        name='trial_mode'
        label='Tryb early access'
        {...props}
    />

}

export default BusinessTrialModeField