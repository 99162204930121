import {
    SET_LOGGED_USER,
    APP_LOADED,
    LOAD_LANGUAGES_SUCCESS, APP_CHANGE_LANGUAGE
} from "../constants/actiontypes/app";
import api from '../core/api';
import {createAction} from "../core/utils/appUtil";
import {getAllLanguages} from "../core/api";
import initWebsockets from "../api/websocket";
import {fetchNotifications} from "./notification";
import {normalize} from 'normalizr';
import schemas from "../reducers/schemas";

export function initApp() {
    return function (dispatch) {
        api.auth.getAccount()
            .then((r) => {
                api.admin.getById(r.data.id)
                    .then((response) => {
                        initAppSuccess(dispatch, response)
                    })
                    .catch((err) => appError(dispatch, err))
            })
            .catch((err) => appError(dispatch, err));
    }
}

function initAppSuccess(dispatch, response) {
    let token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
    if (token === null || token === '') {
        dispatch(createAction(APP_LOADED));
    } else {
        let loggedUser = response.data;
        dispatch(createAction(SET_LOGGED_USER, normalize(loggedUser, schemas.adminSchema)));
        dispatch(createAction(APP_LOADED));
        dispatch(fetchNotifications());
        initWebsockets(loggedUser.id, dispatch);
    }
}

export function appError(dispatch, error) {
    // if(error.response.status >= 500) {
    //     dispatch(createAction(SHOW_ERROR, error.response.data));
    // } else {
    return dispatch(createAction(APP_LOADED));
    // }
}


export function changeLanguage(language) {
    localStorage.setItem('current_language', language)
    return function (dispatch) {
        dispatch(createAction(APP_CHANGE_LANGUAGE, language))
    }
}