import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import {RiEdit2Fill} from 'react-icons/ri'
import {getRoutePath} from "../../../core/utils/routeUtil";
import {ROUTE_BUSINESS_SHOW} from "../../../routes/routeDefinitions";
import {Link} from "react-router-dom";
import {Col} from 'reactstrap'
import {resetBreadcrumbs} from "../../../actions/navbar";
import IconButton from "../../../core/buttons/IconButton";
import Datatable from "../../../core/components/datatable/Datatable";
import {fetchBusinesses} from "../../../actions/business";
import YesNoColumn from "../../../core/components/datatable/YesNoColumn";
import moment from "moment/moment";

let BusinessesPage = () => {

    let dispatch = useDispatch();

    let {allIds: allBusinesses, loading, pagination} = useSelector(state => state.business)

    let businesses = useSelector(state => state.business.byId)

    React.useEffect(() => {
        dispatch(resetBreadcrumbs());
        dispatch(fetchBusinesses())
    }, [dispatch])

    let data = allBusinesses.map(businessId => {
        let business = businesses[businessId]
        return [
            <td>{business.name}</td>,
            <td>
                <YesNoColumn active={business.enabled}/>
            </td>,
            <td>
                {moment(business.created_at).format('YYYY-MM-DD HH:mm:ss')}
            </td>,
            <td>
                <Link to={getRoutePath(ROUTE_BUSINESS_SHOW, businessId)}>
                    <IconButton Icon={RiEdit2Fill}/>
                </Link>
            </td>
        ]
    })

    let loadBusinesses = () => {
        dispatch(fetchBusinesses([]))
    }

    return <div className='list-page'>
        <Col lg={{size: 10, offset: 1}}>
            <Datatable
                loading={loading}
                columnHeaders={['Nazwa', 'Aktywny', 'Data rejestracji', 'Edycja']}
                data={data}
                search={false}
                pagination={pagination}
                onChange={loadBusinesses}
            />
        </Col>
    </div>
}

export default BusinessesPage;

