import React from "react";
import TextInput from "../../../core/form/TextInput";

let BusinessFacebookField = ({control, defaultValue, loading}) => {

    return <TextInput
        loading={loading}
        name='facebook'
        control={control}
        defaultValue={defaultValue}
        label='form.business.facebook'
    />

}

export default BusinessFacebookField