import React from "react";
import {useSelector} from "react-redux";
import SelectInput from "./SelectInput";
import {uniqueArray} from "../../utils/appUtil";

let ReduxSelect = (props) => {

    let {
        stateKey,
        data,
        optionLabel = 'name',
        optionValue = 'id',
        autocomplete = true,
        isAvailable = () => true,
        renderSelectedOption = value => value[optionLabel]
    } = props;

    data = props.elements ? props.elements : data

    let byId = useSelector(state => state[stateKey].byId);

    let defaultValue = props.control?._defaultValues[props.name]

    if (props.isMulti && !defaultValue) {
        defaultValue = []
    }

    if (props.isMulti) {
        if (defaultValue?.length > 0) {
            for (let i = 0; i < defaultValue.length; i++) {
                if (!data.filter(o => o === defaultValue[i]).shift()) {
                    data.push(defaultValue[i])
                }
            }
        }
    } else {
        let val = props.control._formValues[props.name]
        if (val && defaultValue && val === defaultValue && !data.filter(o => o === val).shift()) {
            data.push(val)
        }
    }

    return <SelectInput
        {...props}
        options={data.map(d => byId[d])}
        optionLabel={optionLabel}
        optionValue={optionValue}
        autocomplete={autocomplete}
        renderValue={(value) => {
            if (props.isMulti) {
                value = uniqueArray(value)
                return value.map((val, key) => {
                    let p = byId[val]
                    return <span
                        key={props.name + '_' + val}
                        className={!isAvailable(p.id) ? 'inavailable' : ''}>{renderSelectedOption(p)}{key === value.length - 1 ? '' : ', '}</span>
                })
            }
            return <span
                className={!isAvailable(value) ? 'inavailable' : ''}>{byId[value] ? renderSelectedOption(byId[value]) : ''}</span>
        }}
    />
}

export default ReduxSelect;
