export const RESERVATION_FORM_FETCH_FACILITIES_REQUEST = 'RESERVATION_FORM_FETCH_FACILITIES_REQUEST'
export const RESERVATION_FORM_FETCH_FACILITIES_SUCCESS = 'RESERVATION_FORM_FETCH_FACILITIES_SUCCESS'
export const RESERVATION_FORM_FETCH_SERVICES_REQUEST = 'RESERVATION_FORM_FETCH_SERVICES_REQUEST'
export const RESERVATION_FORM_FETCH_SERVICES_SUCCESS = 'RESERVATION_FORM_FETCH_SERVICES_SUCCESS'
export const RESERVATION_FORM_FETCH_PARTNERS_REQUEST = 'RESERVATION_FORM_FETCH_PARTNERS_REQUEST'
export const RESERVATION_FORM_FETCH_PARTNERS_SUCCESS = 'RESERVATION_FORM_FETCH_PARTNERS_SUCCESS'
export const RESERVATION_FORM_FETCH_RESOURCES_REQUEST = 'RESERVATION_FORM_FETCH_RESOURCES_REQUEST'
export const RESERVATION_FORM_FETCH_RESOURCES_SUCCESS = 'RESERVATION_FORM_FETCH_RESOURCES_SUCCESS'

var initialState = {
    facilities: [],
    facilityAvailability: {},
    loadingFacilities: false,
    services: [],
    serviceAvailability: {},
    loadingServices: false,
    partners: [],
    partnerAvailability: {},
    loadingPartners: false,
    resources: [],
    resourceAvailability: {},
    loadingResources: false
};

function getAvailability(objects) {
    let o = {...objects}
    Object.keys(o).map(function (key, index) {
        o[key] = o[key].availability
        return o
    })
    return o
}

export default (state = initialState, action) => {
    switch (action.type) {
        case RESERVATION_FORM_FETCH_FACILITIES_REQUEST: {
            return {
                ...state,
                loadingFacilities: true
            }
        }
        case RESERVATION_FORM_FETCH_FACILITIES_SUCCESS: {
            return {
                ...state,
                facilities: action.payload.result,
                facilityAvailability: getAvailability(action.payload.entities.facility),
                loadingFacilities: false
            }
        }
        case RESERVATION_FORM_FETCH_SERVICES_REQUEST: {
            return {
                ...state,
                loadingServices: true
            }
        }
        case RESERVATION_FORM_FETCH_SERVICES_SUCCESS: {
            return {
                ...state,
                services: action.payload.result,
                serviceAvailability: getAvailability(action.payload.entities.service),
                loadingServices: false
            }
        }
        case RESERVATION_FORM_FETCH_PARTNERS_REQUEST: {
            return {
                ...state,
                loadingPartners: true
            }
        }
        case RESERVATION_FORM_FETCH_PARTNERS_SUCCESS: {
            return {
                ...state,
                partners: action.payload.result,
                partnerAvailability: getAvailability(action.payload.entities.partner),
                loadingPartners: false
            }
        }
        case RESERVATION_FORM_FETCH_RESOURCES_REQUEST: {
            return {
                ...state,
                loadingResources: true
            }
        }
        case RESERVATION_FORM_FETCH_RESOURCES_SUCCESS: {
            return {
                ...state,
                resources: action.payload.result,
                resourceAvailability: getAvailability(action.payload.entities.resource),
                loadingResources: false
            }
        }
        default:
            return state;
    }
};