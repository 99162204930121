import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import {Col} from 'reactstrap'
import {resetBreadcrumbs} from "../../../actions/navbar";
import Datatable from "../../../core/components/datatable/Datatable";
import moment from "moment/moment";
import {fetchContactRequests} from "../../../actions/contactRequest";
import Email from "../../../core/components/datatable/Email";

let ContactRequestsPage = () => {

    let dispatch = useDispatch();

    let {allIds: all, loading, pagination} = useSelector(state => state.contactRequest)

    let contactRequests = useSelector(state => state.contactRequest.byId)

    React.useEffect(() => {
        dispatch(resetBreadcrumbs());
        dispatch(fetchContactRequests())
    }, [dispatch])

    let data = all.map(contactRequestId => {
        let contactRequest = contactRequests[contactRequestId]
        return [
            <td>{contactRequest.name}</td>,
            <td>
                <Email email={contactRequest.email}/>
            </td>,
            <td>
                {moment(contactRequest.created_at).format('YYYY-MM-DD HH:mm:ss')}
            </td>,
            <td>{contactRequest.content}</td>
        ]
    })

    let loadData = () => {
        dispatch(fetchContactRequests())
    }

    return <div className='list-page'>
        <Col lg={{size: 10, offset: 1}}>
            <Datatable
                loading={loading}
                columnHeaders={['Nazwa', 'Adres email', 'Data utworzenia']}
                data={data}
                search={false}
                pagination={pagination}
                onChange={loadData}
            />
        </Col>
    </div>
}

export default ContactRequestsPage;

