import React from "react";
import {Row, Col} from 'reactstrap'
import BusinessPage from "./BusinessPage";
import {fetchBusinessPartners} from "../../../actions/business";
import {useDispatch, useSelector} from "react-redux";
import {Link, useRouteMatch} from "react-router-dom";
import Datatable from "../../../core/components/datatable/Datatable";
import YesNoColumn from "../../../core/components/datatable/YesNoColumn";
import moment from "moment";
import {getRoutePath} from "../../../core/utils/routeUtil";
import {ROUTE_PARTNER_SHOW} from "../../../routes/routeDefinitions";
import IconButton from "../../../core/buttons/IconButton";
import {RiEdit2Fill} from "react-icons/ri";
import Button from "../../../core/buttons/Button";
import {loginAsPartner} from "../../../actions/partners";

let BusinessPartnersPage = () => {

    let match = useRouteMatch();

    let {loadingPartners: loading} = useSelector(state => state.business)

    let businessId = match.params.id

    let dispatch = useDispatch();

    let partners = useSelector(state => state.partner.byBusiness[businessId])

    let allPartners = useSelector(state => state.partner.byId)

    React.useEffect(() => {
        dispatch(fetchBusinessPartners(businessId))
    }, [businessId, dispatch])

    let data = partners?.map(partnerId => {
        let partner = allPartners[partnerId]
        return [
            <td>{partner.fullname}</td>,
            <td>
                <YesNoColumn active={partner.enabled}/>
            </td>,
            <td>
                {moment(partner.created_at).format('YYYY-MM-DD HH:mm:ss')}
            </td>,
            <td>
                <Button type='submit' text='Zaloguj jako' color='success' onClick={() => dispatch(loginAsPartner(partnerId))}/>
            </td>,
            <td>
                <Link to={getRoutePath(ROUTE_PARTNER_SHOW, partnerId)}>
                    <IconButton Icon={RiEdit2Fill}/>
                </Link>
            </td>
        ]
    })

    return <BusinessPage businessId={businessId}>
        <Row className='fitheight'>
            <Col lg={{size: 10, offset: 1}}>
                <Datatable
                    loading={loading}
                    columnHeaders={['Imię i nazwisko', 'Aktywny', 'Data rejestracji', 'Edycja']}
                    data={data}
                    search={false}
                />
            </Col>
        </Row>
    </BusinessPage>

}

export default BusinessPartnersPage
