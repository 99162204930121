import React from "react";

import {Container} from "reactstrap";
import Main from "../components/Main";
import {useHistory} from "react-router-dom";
import {useSelector} from 'react-redux';
import * as routes from "../routes/routeDefinitions";
import backgroundImg from "../assets/img/login-background.png";
import logo from "../assets/img/logo_blue.svg";

let Auth = (props) => {

    let {children} = props;

    let loggedUser = useSelector(state => state.auth.logged_user)
    console.log('props', loggedUser, children)
    let history = useHistory();

    if (loggedUser) {
        console.log('redirect')
        history.push(routes.ROUTE_DASHBOARD);
    }

    return (
        <React.Fragment>
            <Main className="auth">
                <Container>
                    <div className='background'>
                        <img alt='' src={logo} style={{height: '50px'}}/>
                        <img alt='' src={backgroundImg}/>
                        <span className='join-us-text'>Dołącz do nas!</span>
                    </div>
                    <div className='content'>
                        {children}
                    </div>
                </Container>
            </Main>
        </React.Fragment>
    )
}

export default Auth
