import {withRouter} from "react-router-dom";
import {Collapse} from "reactstrap";
import React from "react";
import {MdKeyboardArrowDown} from 'react-icons/md';
const SidebarCategory = withRouter(
    ({
         name,
         isActive,
         icon: Icon,
         isOpen,
         children,
         onClick,
         to
     }) => {
        const getSidebarItemClass = path => {
            return isActive ? 'active' : (isOpen ? 'open' : '');
        };

        return <div className={"sidebar-item has-child " + getSidebarItemClass(to)}>
                <div
                    data-toggle="collapse"
                    className={"sidebar-item-header " + (!isOpen ? "collapsed" : "")}
                    onClick={onClick}
                    aria-expanded={isOpen ? "true" : "false"}
                >
                        {Icon ? <Icon size={16}/> : null}
                        <div className='sidebar-text'>
                            {name}
                        </div>
                        <MdKeyboardArrowDown size={16} className='arrow'/>
                </div>
                <Collapse isOpen={isOpen}>
                    {children}
                </Collapse>
            </div>
    }
);

export default SidebarCategory;