import React from "react";
import TextInput from "../../../core/form/TextInput";

let PartnerPasswordField = ({label, control}) => {

    return <TextInput
        type='password'
        name='password'
        control={control}
        required={true}
        label={label ?? 'form.password'}
    />

}

export default PartnerPasswordField