export const ADMIN_LIST_REQUEST = 'ADMIN_LIST_REQUEST';
export const ADMIN_LIST_SUCCESS = 'ADMIN_LIST_SUCCESS';

let initialState = {
    loading: false,
    byId: {},
    allIds: []
};

export default (state = initialState, action) => {
    if (action.payload?.entities?.admin) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.admin
            }
        }
    }
    switch (action.type) {
        case ADMIN_LIST_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ADMIN_LIST_SUCCESS: {
            return {
                ...state,
                allIds: action.payload.result,
                loading: false,
                pagination: action.payload.meta
            }
        }
        default:
            return state;
    }
};