import React from "react";
import ObjectPage from "../../../core/components/objectpage/ObjectPage";
import {
    ROUTE_BUSINESS_PARTNERS,
    ROUTE_BUSINESS_RESERVATION_SETTINGS,
    ROUTE_BUSINESS_SHOW, ROUTE_BUSINESSES
} from "../../../routes/routeDefinitions";
import {resetBreadcrumbs, setBreadcrumbs} from "../../../actions/navbar";
import {useDispatch} from 'react-redux'
import {getRoutePath} from "../../../core/utils/routeUtil";

let BusinessPage = ({businessId, children}) => {

    let dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setBreadcrumbs('Biznesy', getRoutePath(ROUTE_BUSINESSES)))
    })

    let pages = [
        {
            name: 'Informacje',
            path: getRoutePath(ROUTE_BUSINESS_SHOW, businessId),
        }, {
            name: 'Pracownicy',
            path: getRoutePath(ROUTE_BUSINESS_PARTNERS, businessId)
        }
    ]

    return <ObjectPage pages={pages} name='business'>
        {children}
    </ObjectPage>

}

export default BusinessPage
