import React from "react";
import TextInput from "../../../core/form/TextInput";

let BusinessSubdomainField = ({control, setValue, watch, defaultValue, loading}) => {

    React.useEffect(() => {
        const subscription = watch(({name: businessName}, {name: fieldName}) => {
            if (fieldName === 'name' && setValue) {
                setValue('subdomain', businessName.toLowerCase().replace(/[\W_]+/g, ""))
            }
        });
        return () => subscription.unsubscribe();
    }, [
        watch, setValue
    ])

    return <div className='subdomain-field'>
        <div className='domain'>
            <span>https://bookendy.com/</span>
        </div>
        <TextInput
            loading={loading}
            name='subdomain'
            control={control}
            defaultValue={defaultValue}
            label='form.business.subdomain'
            required={true}
        />
    </div>

}

export default BusinessSubdomainField