import React from "react";
import {Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {logout} from "../actions/auth";
import { useHistory } from "react-router-dom";
import * as routes from '../routes/routeDefinitions';
const Logout = () => {

    let dispatch = useDispatch();

    let history = useHistory();

    dispatch(logout(history));

    return <Redirect to={routes.ROUTE_DASHBOARD} />;
};

export default Logout;
