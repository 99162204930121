import {
    RESERVATION_LIST_ERROR,
    RESERVATION_SET_FILTERS,
    RESERVATION_STATUS_UPDATE_SUCCESS,
    RESERVATIONS_SET_CALENDAR_VIEW
} from "../constants/actiontypes/reservations";
import {
    DASHBOARD_FETCH_RESERVATIONS_REQUEST,
    DASHBOARD_FETCH_RESERVATIONS_SUCCESS
} from "../constants/actiontypes/dashboard";
import {PAYMENT_CREATE_SUCCESS, PAYMENT_REMOVE_SUCCESS} from "../constants/actiontypes/payment";

var initialState = {
        byId: [],
        all: [],
        datatable: {
            skipRerender: false,
            data: []
        },
        current: null,
        calendarView: true,
        loadingCalendar: true
    }

export const RESERVATION_ADD_SUCCESS = 'RESERVATION_ADD_SUCCESS';
export const RESERVATION_UPDATE_SUCCESS = 'RESERVATION_UPDATE_SUCCESS';

export default (state = initialState, action) => {
    if (action.payload?.entities?.reservation) {
        state = {
            ...state,
            byId: {
                ...state.byId,
                ...action.payload.entities.reservation
            }
        }
    }
    switch (action.type) {
        case DASHBOARD_FETCH_RESERVATIONS_REQUEST: {
            return {
                ...state,
                loadingCalendar: true
            }
        }
        case RESERVATIONS_SET_CALENDAR_VIEW: {
            return {
                ...state,
                calendarView: action.payload
            };
        }
        case RESERVATION_UPDATE_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: action.payload
                },
                current: action.payload.id
            }
        }
        case DASHBOARD_FETCH_RESERVATIONS_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    ...action.payload.entities.reservation
                },
                calendarIds: action.payload.result,
                loadingCalendar: false
            }
        }
        case RESERVATION_STATUS_UPDATE_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.reservation_id]: {
                        ...state.byId[action.payload.reservation_id],
                        status: action.payload.status
                    }
                }
            }
        }
        case PAYMENT_CREATE_SUCCESS: {
            if (action.payload.entities.payment[action.payload.result].reservation_id) {
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.payload.entities.payment[action.payload.result].reservation_id]: {
                            ...state.byId[action.payload.entities.payment[action.payload.result].reservation_id],
                            payments: [
                                ...state.byId[action.payload.entities.payment[action.payload.result].reservation_id].payments,
                                action.payload.result
                            ]
                        }
                    }
                }
            }
            return state;
        }
        case PAYMENT_REMOVE_SUCCESS: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.payload.id]: {
                        ...state.byId[action.payload.id],
                        payments: state.byId[action.payload.id].payments.filter(payment => payment !== action.payload.paymentId)
                    }
                }
            }
        }
        case RESERVATION_SET_FILTERS: {
            return {
                ...state,
                filters: action.payload,
                datatable: {
                    ...state.datatable,
                    pageCount: 0
                }
            }
        }
        case RESERVATION_LIST_ERROR : {
            return {
                ...state,
                datatable: {
                    ...state.datatable,
                    loading: false
                }
            }
        }
        default:
            return state;
    }
}