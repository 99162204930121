import React from "react";
import TextInput from "../../../core/form/TextInput";

let PartnerFullnameField = (props) => {

    return <TextInput
        name='fullname'
        label='form.partner.fullname'
        {...props}
    />

}

export default PartnerFullnameField