export const ROUTE_DASHBOARD = '/';
export const ROUTE_CLIENT_SHOW = '/klienci/:id';
export const ROUTE_BUSINESSES = '/biznesy'
export const ROUTE_CONTACT_REQUESTS = '/formularze-kontaktowe'
export const ROUTE_BUSINESS_SHOW = '/biznesy/:id'
export const ROUTE_BUSINESS_PARTNERS = '/biznesy/:id/pracownicy'
export const ROUTE_FACILITY_SHOW = '/obiekty/:id';
export const ROUTE_SERVICE_DETAILS = '/uslugi/:id';
export const ROUTE_PARTNER_SHOW = '/personel/:id';
export const ROUTE_RESOURCE_SHOW = '/zasoby/:id';
export const ROUTE_SEND_RESET_PASSWORD_LINK = '/reset-hasla';
export const ROUTE_BUSINESS_RESERVATION_SETTINGS = '/biznes/rezerwacje';
export const ROUTE_ACCOUNT_DETAILS = '/ustawienia';
export const ROUTE_LOGOUT = '/wyloguj'
export const ROUTE_SIGN_IN = '/signin';