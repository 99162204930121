import update from 'immutability-helper';
import {AVAILABILITY_TYPE_CUSTOM} from "../core/constants/reservationStatuses";

export const OPENING_HOUR_FORM_RESET = 'OPENING_HOUR_FORM_RESET';
export const OPENING_HOUR_FORM_INIT = 'OPENING_HOUR_FORM_INIT';
export const OPENING_HOUR_FORM_CHANGE_AVAILABILITY_TYPE = 'OPENING_HOUR_FORM_CHANGE_AVAILABILITY_TYPE';
export const OPENING_HOUR_FORM_SET_ENABLED_DAY = 'OPENING_HOUR_FORM_SET_ENABLED_DAY';
export const OPENING_HOUR_FORM_ADD = 'OPENING_HOUR_FORM_ADD'
export const OPENING_HOUR_FORM_CHANGE_TIME = 'OPENING_HOUR_FORM_CHANGE_TIME'
export const OPENING_HOUR_FORM_REMOVE = 'OPENING_HOUR_FORM_REMOVE'
export const OPENING_HOUR_FORM_SAVED = 'OPENING_HOUR_FORM_SAVED'

var defaultDisabledDay = {
    enabled: false,
    hours: [
        {
            time_from: {
                hour: 7,
                minute: 0
            },
            time_to: {
                hour: 15,
                minute: 0
            }
        }
    ]
}

let allDays = [0, 1, 2, 3, 4, 5, 6]

var initialState = {
    loading: true,
    formChanged: false,
    availability_type: AVAILABILITY_TYPE_CUSTOM,
    days: {
        0: defaultDisabledDay,
        1: defaultDisabledDay,
        2: defaultDisabledDay,
        3: defaultDisabledDay,
        4: defaultDisabledDay,
        5: defaultDisabledDay,
        6: defaultDisabledDay
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case OPENING_HOUR_FORM_RESET: {
            return {
                ...state,
                loading: true,
                formChanged: false
            }
        }
        case OPENING_HOUR_FORM_INIT: {
            return {
                ...state,
                loading: false,
                availability_type: action.payload.availability_type ?? initialState.availability_type,
                days: allDays.map(day => {
                    let openingHours = action.payload.entities.openingHour
                    if (!openingHours) {
                        return defaultDisabledDay
                    }
                    let hours = Object.keys(openingHours)
                        .filter(o => openingHours[o].day === day)
                        .map(o => {
                            return {
                                time_from: openingHours[o].time_from,
                                time_to: openingHours[o].time_to
                            }
                        })
                    return {
                        enabled: hours.length > 0,
                        hours: hours.length > 0 ? hours : defaultDisabledDay.hours
                    }
                })
            }
        }
        case OPENING_HOUR_FORM_SET_ENABLED_DAY: {
            return {
                ...state,
                formChanged: true,
                days: {
                    ...state.days,
                    [action.payload.day]: {
                        ...state.days[action.payload.day],
                        enabled: action.payload.enabled,
                    },
                }
            }
        }
        case OPENING_HOUR_FORM_ADD: {
            return {
                ...state,
                formChanged: true,
                days: {
                    ...state.days,
                    [action.payload.day]: {
                        ...state.days[action.payload.day],
                        hours: [
                            ...state.days[action.payload.day].hours,
                            {
                                time_from: action.payload.timeFrom,
                                time_to: action.payload.timeTo
                            }
                        ]
                    },
                }
            }
        }
        case OPENING_HOUR_FORM_CHANGE_TIME: {
            return {
                ...state,
                formChanged: true,
                days: {
                    ...state.days,
                    [action.payload.day]: {
                        ...state.days[action.payload.day],
                        hours: update(state.days[action.payload.day].hours, {[action.payload.index]: {$set: action.payload.time}})
                    }
                }
            }
        }
        case OPENING_HOUR_FORM_REMOVE: {
            return {
                ...state,
                formChanged: true,
                days: {
                    ...state.days,
                    [action.payload.day]: {
                        ...state.days[action.payload.day],
                        hours: update(state.days[action.payload.day].hours, {$splice: [[action.payload.index, 1]]})
                    }
                }
            }
        }
        case OPENING_HOUR_FORM_SAVED: {
            return {
                ...state,
                formChanged: false
            }
        }
        case OPENING_HOUR_FORM_CHANGE_AVAILABILITY_TYPE : {
            return {
                ...state,
                availability_type: action.payload,
                formChanged: true
            }
        }
        default:
            return state;
    }
};