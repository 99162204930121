import React from "react";
import {useSelector, useDispatch} from 'react-redux';
import {Col} from 'reactstrap'
import {resetBreadcrumbs} from "../../../actions/navbar";
import Datatable from "../../../core/components/datatable/Datatable";
import {fetchAdmins} from "../../../actions/admin";
import Email from "../../../core/components/datatable/Email";
import IconButton from "../../../core/buttons/IconButton";
import {RiEdit2Fill} from 'react-icons/ri'
import AvatarColumn from "../../../core/components/datatable/AvatarColumn";

let AdminsPage = () => {

    let dispatch = useDispatch();

    let {allIds: allAdmins, loading, pagination} = useSelector(state => state.admin)

    let admins = useSelector(state => state.admin.byId)

    React.useEffect(() => {
        dispatch(resetBreadcrumbs());
        dispatch(fetchAdmins())
    }, [dispatch])

    let data = allAdmins.map(adminId => {
        let admin = admins[adminId]
        return [
            <td className='avatar-column'><AvatarColumn name={admin.fullname} id={admin.id}/></td>,
            <td>{admin.fullname}</td>,
            <td><Email email={admin.email}/></td>,
            <td>
                <IconButton Icon={RiEdit2Fill}/>
            </td>
        ]
    })

    let loadAdmins = () => {
        dispatch(fetchAdmins([]))
    }

    return <div className='list-page'>
        <Col lg={{size: 10, offset: 1}}>
            <Datatable
                loading={loading}
                columnHeaders={['', 'Imię i nazwisko', 'Adres e-mail']}
                data={data}
                search={false}
                pagination={pagination}
                onChange={loadAdmins}
            />
        </Col>
    </div>
}

export default AdminsPage;

