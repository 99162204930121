export default {
    add_new_client: 'Dodaj nowego klienta',
    resource_types: {
        missing_resources: 'Nie dodałeś jeszcze żadnych zasobów :(',
        configure: 'Skonfiguruj zasoby',
        configure_modal_header: 'Konfiguracja zasobów',
        question: 'Ile zasobów jest dostępnych w tym obiekcie?',
        question_if: 'Czy posiadasz więcej niż jeden zasób?',
        "bowling-alley": {
            missing_resources: 'Nie dodałeś jeszcze żadnych torów do kręgli :(',
            configure: 'Skonfiguruj tory do kręgli',
            configure_modal_header: 'Konfiguracja torów do kręgli',
            question: 'Ile torów do kręgli posiadasz?'
        },
        "sports-hall-sector": {
            missing_resources: 'Nie wprowadziłeś jeszcze podziału hali sportowej na sektory',
            configure: 'Konfiguracja sektorów hali sportowej',
            configure_modal_header: 'Konfiguracja sektorów hali sportowej',
            question: 'Ile sektorów posiada Twoja hala sportowa?',
            question_if: 'Czy chcesz wynajmować halę na sektory?'
        },
        "squash-court": {
            missing_resources: 'Nie dodałeś jeszcze żadnych kortów :(',
            configure: 'Konfiguracja kortów',
            configure_modal_header: 'Konfiguracja kortów',
            question: 'Ile kortów do Squacha posiadasz?',
            question_if: 'Czy posiadasz więcej niż jeden kort?'
        },
        "tennis-court": {
            missing_resources: 'Nie dodałeś jeszcze żadnych kortów :(',
            configure: 'Konfiguracja kortów',
            configure_modal_header: 'Konfiguracja kortów',
            question: 'Ile kortów do tenisa posiadasz?',
            question_if: 'Czy posiadasz więcej niż jeden kort?'
        },
        "sport-field-artificial-sector": {
            missing_resources: 'Nie wprowadziłeś jeszcze podziału boiska na części :(',
            configure: 'Konfiguracja części boiska',
            configure_modal_header: 'Konfiguracja części boiska',
            question: 'Ile części boiska chcesz wynajmować?',
            question_if: 'Czy chcesz wynajmować części boiska?'
        },
        "sport-field-natural-sector": {
            missing_resources: 'Nie wprowadziłeś jeszcze podziału boiska na części :(',
            configure: 'Konfiguracja części boiska',
            configure_modal_header: 'Konfiguracja części boiska',
            question: 'Ile części boiska chcesz wynajmować?',
            question_if: 'Czy chcesz wynajmować części boiska?'
        }
    }
}