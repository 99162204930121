import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Form} from 'reactstrap';
import {Link, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useIntl} from 'react-intl';
import {setErrors} from "../../../../core/utils/formUtil";
import {submitLogin} from "../../../../actions/auth";
import {ROUTE_SEND_RESET_PASSWORD_LINK} from "../../../../routes/routeDefinitions";
import PartnerEmailField from "../../partner/PartnerEmailField";
import PartnerPasswordField from "../../partner/PartnerPasswordField";
import SubmitFormButton from "../../../../core/buttons/SubmitFormButton";
import {trans} from "../../../../core/utils/translationUtil";
import Button from '@mui/material/Button';

let SignInForm = () => {

    let [isDuringSubmit, setIsDuringSubmit] = useState(false);

    let dispatch = useDispatch();
    let history = useHistory();

    let intl = useIntl();

    let submit = async (data) => {
        setIsDuringSubmit(true);
        let result = await submitLogin(dispatch, history, data);
        setErrors(result, setError, intl);
        setIsDuringSubmit(false);
    }

    const {control, handleSubmit, setError} = useForm();

    return <Form onSubmit={handleSubmit(submit)}>
        <PartnerEmailField control={control}/>
        <PartnerPasswordField control={control}/>
        <SubmitFormButton loading={isDuringSubmit} text='app.signin'/>
    </Form>

}
export default SignInForm
