import React from "react";
import SelectInput from "../../core/form/select/SelectInput";

let TimezoneSelect = (props) => {

    function getOptions() {
        return [{
            value: 'Europe/Warsaw',
            label: 'Europe/Warsaw'
        }, {
            value: 'Europe/London',
            label: 'Europe/London'
        }];
    }

    return <SelectInput name='timezone' {...props} label='form.business.timezone' options={getOptions()}/>
}

export default TimezoneSelect