import React from "react";
import ObjectPage from "../../core/components/objectpage/ObjectPage";
import {
    ROUTE_BUSINESS_PARTNERS,
    ROUTE_BUSINESS_SHOW,
    ROUTE_PARTNER_SHOW
} from "../../routes/routeDefinitions";
import {getRoutePath} from "../../core/utils/routeUtil";
import {useDispatch, useSelector} from 'react-redux'
import {setBreadcrumbs} from "../../actions/navbar";

let PartnerPage = ({partnerId, children}) => {

    let pages = [
        {
            name: 'Dane pracownika',
            path: getRoutePath(ROUTE_PARTNER_SHOW, partnerId),
        }
    ]

    let dispatch = useDispatch();

    let partner = useSelector(state => state.partner.byId[partnerId])

    React.useEffect(() => {
        dispatch(setBreadcrumbs('Biznes - pracownicy', getRoutePath(ROUTE_BUSINESS_PARTNERS, {id: partner?.business_id})))
    }, [dispatch])

    return <ObjectPage pages={pages} name='partner'>
        {children}
    </ObjectPage>

}

export default PartnerPage
