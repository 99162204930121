import { ReactComponent as CalendarIcon } from '../assets/img/icons/calendar.svg'
import BusinessesPage from "../pages/dashboard/business/BusinessesPage";
import {ROUTE_BUSINESSES, ROUTE_CONTACT_REQUESTS} from "./routeDefinitions";
import ContactRequestsPage from "../pages/dashboard/contactrequest/ContactRequestsPage";

const businesses = {
    path: ROUTE_BUSINESSES,
    name: "Biznesy",
    headerText: 'Biznesy',
    component: BusinessesPage,
    icon: CalendarIcon,
    children: null
}
const contactRequests = {
    path: ROUTE_CONTACT_REQUESTS,
    name: "Formularze kontaktowe",
    headerText: 'Formularze kontaktowe',
    component: ContactRequestsPage,
    icon: CalendarIcon,
    children: null
}

export default [
    businesses, contactRequests
];