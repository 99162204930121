import * as authTypes from '../constants/actiontypes/auth';
import {createAction} from "../core/utils/appUtil";
import api from '../core/api';
import {toastError, toastSuccess} from "../core/utils/appUtil";
import * as routes from "../routes/routeDefinitions";
import schemas from "../reducers/schemas";
import {normalize} from 'normalizr';
import handleError from "../core/utils/errorUtil";

export async function submitLogin(dispatch, history, formData) {
    return await api.auth.loginAdmin(formData.email, formData.password).then((response) => {
        let data = response.data;
        if (data.access_token) {
            localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, data.access_token);
            dispatch(createAction(authTypes.SET_LOGGED_USER, normalize(data.user, schemas.adminSchema)));
            history.push(routes.ROUTE_DASHBOARD);
            toastSuccess('Zalogowano');
        } else {
            return {
                errors: {
                    password: 'INVALID_PASSWORD'
                }
            };
        }
    }).catch(error => {
        console.log('asdf', error)
        if (error?.response?.status === 401) {
            toastError('Niepoprawne dane logowania');
            return error?.response?.data;
        }
        if (error?.response?.status === 422) {
            toastError('Konto zablokowane');
            return error?.response?.data;
        }
        return handleError(error, dispatch)
    });
}


export function logout() {
    return function (dispatch) {
        localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
        dispatch(createAction(authTypes.LOGOUT));
        toastSuccess('Wylogowano');
    };
}

export async function submitRegister(formData, languageId, history, dispatch) {
    formData.language_id = languageId;
    return await api.auth.registerPartner(formData).then((response) => {
        let data = response.data.data;
        if (data.access_token) {
            localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, data.access_token);
            dispatch(createAction(authTypes.SET_LOGGED_USER, normalize(data.partner, schemas.partnerSchema)));
            history.push(routes.ROUTE_DASHBOARD);
            toastSuccess('Zalogowano');
        }
    }).catch(error => handleError(error, dispatch));
}

export function getJwtToken() {
    return localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
}

export function setJwtToken(token) {
    return localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, token);
}

export async function confirmAccount(formData, dispatch, history) {
    return await api.auth.confirmAccount(formData).then((response) => {
        history.push(routes.ROUTE_SIGN_IN);
        toastSuccess('Konto zostało aktywowane');
    }).catch(error => handleError(error, dispatch));
}

export async function sendResetPasswordLink(formData, dispatch, history) {
    return await api.auth.resetPasswordLink(formData).then(() => {
        history.push(routes.ROUTE_SIGN_IN);
        toastSuccess('Jeśli w systemie istnieje podany adres e-mail to wiadomość z linkiem pozwalającym zresetować hasło została wysłana')
        return {
            success: true
        }
    }).catch(error => handleError(error, dispatch));
}

export async function resetPassword(id, formData, history, dispatch) {
    return await api.auth.resetPassword(id, formData).then((response) => {
        toastSuccess('Hasło zostało zresetowane')
        history.push(routes.ROUTE_SIGN_IN);
    }).catch(error => handleError(error, dispatch));
}