import React from "react";
import TextInput from "../../../core/form/TextInput";

let BusinessNameField = ({control, defaultValue, loading}) => {

    return <TextInput
        loading={loading}
        name='name'
        control={control}
        defaultValue={defaultValue}
        label='form.business.name'
        required={true}
    />

}

export default BusinessNameField