import {createRequest, makeRequest, prepareRequest} from "../utils/apiUtil";

export function getAll(filters = {}, includes = [], extraParams = {}) {
    return makeRequest(createRequest('business'), prepareRequest(filters, includes, extraParams));
}

export function findBySubdomain(subdomain, includes = []) {
    return getAll({subdomain}, includes)
}

export function get(id) {
    return makeRequest(createRequest('business/:id', 'GET', []), {}, {id});
}
export function getPartners(id) {
    return makeRequest(createRequest('business/:id/partner', 'GET', []), {}, {id});
}