import React from "react";
import TextInput from "../../../core/form/TextInput";

let BusinessWebsiteField = ({control, defaultValue, loading}) => {

    return <TextInput
        name='website'
        loading={loading}
        control={control}
        defaultValue={defaultValue}
        label='form.business.website'
    />

}

export default BusinessWebsiteField