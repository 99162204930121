import React from "react";
import {useDispatch, useSelector} from "react-redux";
import * as api from "../../../../core/api";
import {createAction} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {normalize} from 'normalizr';
import BusinessInstagramField from "../../business/BusinessInstagramField";
import BusinessWebsiteField from "../../business/BusinessWebsiteField";
import BusinessFacebookField from "../../business/BusinessFacebookField";
import BusinessSubdomainField from "../../business/BusinessSubdomainField";
import BusinessNameField from "../../business/BusinessNameField";
import {BUSINESS_UPDATE_SUCCESS} from "../../../../reducers/business";
import TimezoneSelect from "../../TimezoneSelect";
import CardForm from "../../../../core/form/CardForm";
import FormHeader from "../../../../core/form/FormHeader";
import BusinessEnabledField from "./BusinessEnabledField";
import BusinessTrialModeField from "./BusinessTrialModeField";

let BusinessDetailsForm = ({businessId}) => {

    let business = useSelector(state => state.business.byId[businessId]);

    let loading = useSelector(state => state.business.loading)

    let dispatch = useDispatch();

    let prepareData = data => {
        return {
            enabled: data.enabled,
            trial_mode: data.trial_mode,
            name: data.name,
            subdomain: data.subdomain,
            facebook: data.facebook,
            website: data.website,
            instagram: data.instagram,
            timezone: data.timezone
        }
    }

    let renderForm = (form) => <>
        <FormHeader text='Ustawienia biznesu'/>
        <BusinessNameField control={form.control} loading={loading}/>
        <BusinessSubdomainField control={form.control} watch={form.watch} loading={loading}/>
        <TimezoneSelect control={form.control} loading={loading}/>
        <BusinessEnabledField control={form.control} loading={loading}/>
        <BusinessTrialModeField control={form.control} loading={loading}/>
        <FormHeader text='Moje linki'/>
        <BusinessFacebookField control={form.control} loading={loading}/>
        <BusinessWebsiteField control={form.control} loading={loading}/>
        <BusinessInstagramField control={form.control} loading={loading}/>
    </>

    return <CardForm
        title='Informacje'
        renderForm={renderForm}
        data={business}
        prepareData={prepareData}
        getPromise={(id, data) => api.updateBusiness(data, id)}
        onSubmit={data => dispatch(createAction(BUSINESS_UPDATE_SUCCESS, normalize(data.data, schemas.businessSchema)))}/>
}

export default BusinessDetailsForm