import React from 'react';
import {
    ROUTE_BUSINESS_PARTNERS,
    ROUTE_BUSINESS_SHOW,
    ROUTE_BUSINESSES, ROUTE_CONTACT_REQUESTS,
    ROUTE_DASHBOARD,
    ROUTE_LOGOUT, ROUTE_PARTNER_SHOW
} from "./routeDefinitions";
import Logout from "../components/Logout";
import BusinessesPage from "../pages/dashboard/business/BusinessesPage";
import BusinessDetailsPage from "../pages/dashboard/business/BusinessDetailsPage";
import BusinessPartnersPage from "../pages/dashboard/business/BusinessPartnersPage";
import PartnerDetailsPage from "../pages/partner/PartnerDetailsPage";
import AdminsPage from "../pages/dashboard/admin/AdminsPage";
import ContactRequestsPage from "../pages/dashboard/contactrequest/ContactRequestsPage";

let createRoute = (r, Component, render) => {
    return {route: r, render: (render ?? (() => <Component/>))}
}

export default [
    createRoute(ROUTE_LOGOUT, Logout),
    createRoute(ROUTE_DASHBOARD, AdminsPage),
    createRoute(ROUTE_BUSINESSES, BusinessesPage),
    createRoute(ROUTE_BUSINESS_SHOW, BusinessDetailsPage),
    createRoute(ROUTE_BUSINESS_PARTNERS, BusinessPartnersPage),
    createRoute(ROUTE_PARTNER_SHOW, PartnerDetailsPage),
    createRoute(ROUTE_CONTACT_REQUESTS, ContactRequestsPage),
]