import {createAction} from "../core/utils/appUtil";
import api from '../core/api';
import {normalize} from 'normalizr';
import schemas from "../reducers/schemas";
import handleError from "../core/utils/errorUtil";
import {CONTACT_REQUEST_LIST_REQUEST, CONTACT_REQUEST_LIST_SUCCESS} from "../reducers/contactRequest";

export function fetchContactRequests() {
    return function (dispatch) {
        dispatch(createAction(CONTACT_REQUEST_LIST_REQUEST));
        return api.contactRequest.get().then(function (response) {
            let normalizedData = normalize(response.data, [schemas.contactRequestSchema]);
            normalizedData.meta = response.data.meta
            dispatch(createAction(CONTACT_REQUEST_LIST_SUCCESS, normalizedData));
        })
            .catch(error => handleError(error, dispatch));
    };
}
