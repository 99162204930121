import React from 'react';
import {BrowserRouter} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {IntlProvider} from "react-intl";
import Routes from "./routes/Routes";
import AppLoading from "./components/AppLoading";
import * as actions from "./actions/app";
import langData from './lang/langData';
import ErrorModal from "./components/ErrorModal";

let App = () => {

    let dispatch = useDispatch()

    let {
        error,
        is_loading: isLoading,
    } = useSelector(state => state.app)

    let currentLanguage = 'pl'

    React.useEffect(() => {
        dispatch(actions.initApp())
    }, [])

    if (isLoading) {
        return <AppLoading/>
    }

    return <BrowserRouter>
        {error && <ErrorModal/>}
        <IntlProvider
            key={currentLanguage}
            locale={currentLanguage}
            messages={langData[currentLanguage]}>
            <Routes/>
        </IntlProvider>
    </BrowserRouter>
}

export default App;