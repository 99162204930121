import React from "react";
import TextInput from "../../../core/form/TextInput";

let PartnerEmailField = (props) => {

    return <TextInput
        type='email'
        name='email'
        label='form.partner.email'
        required={true}
        {...props}
    />

}

export default PartnerEmailField