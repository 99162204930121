export const CREATOR_SET_FACILITY = 'CREATOR_SET_FACILITY';
export const CREATOR_SET_FACILITY_LOADED = 'CREATOR_SET_FACILITY_LOADED';
export const CREATOR_SET_OPENING_HOURS = 'CREATOR_SET_OPENING_HOURS';
export const CREATOR_SET_EMPLOYEE_LOADED = 'CREATOR_SET_EMPLOYEE_LOADED';
export const CREATOR_ADD_EMPLOYEE = 'CREATOR_ADD_EMPLOYEE';
export const CREATOR_UPDATE_EMPLOYEE = 'CREATOR_UPDATE_EMPLOYEE';
export const CREATOR_DELETE_EMPLOYEE = 'CREATOR_DELETE_EMPLOYEE';
export const CREATOR_SET_SERVICES_LOADED = 'CREATOR_SET_SERVICES_LOADED';
export const CREATOR_ADD_SERVICE = 'CREATOR_ADD_SERVICE';
export const CREATOR_UPDATE_SERVICE = 'CREATOR_UPDATE_SERVICE';
export const CREATOR_DELETE_SERVICE = 'CREATOR_DELETE_SERVICE';
export const CREATOR_RESET = 'CREATOR_RESET';

var initialState = {
    facilityLoaded: false,
    employeeLoaded: false,
    servicesLoaded: false,
    facility_id: null,
    employees: [],
    services: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATOR_RESET: {
            return {
                ...initialState
            }
        }
        case CREATOR_SET_FACILITY:
            return {
                ...state,
                facility_id: action.payload.result,
                facilityLoaded: true
            }
        case CREATOR_SET_FACILITY_LOADED: {
            return {
                ...state,
                facilityLoaded: true
            }
        }
        case CREATOR_SET_EMPLOYEE_LOADED: {
            return {
                ...state,
                employeeLoaded: true,
                employees: action.payload.result
            }
        }
        case CREATOR_ADD_EMPLOYEE: {
            return {
                ...state,
                employees: [
                    ...state.employees,
                    action.payload.result
                ]
            }
        }
        case CREATOR_DELETE_EMPLOYEE: {
            return {
                ...state,
                employees: [
                    ...state.employees.filter(e => e !== action.payload)
                ]
            }
        }
        case CREATOR_SET_SERVICES_LOADED: {
            return {
                ...state,
                servicesLoaded: true,
                services: action.payload.result
            }
        }
        case CREATOR_ADD_SERVICE: {
            return {
                ...state,
                services: [
                    ...state.services,
                    action.payload.result
                ]
            }
        }
        case CREATOR_DELETE_SERVICE: {
            return {
                ...state,
                services: [
                    ...state.services.filter(e => e !== action.payload)
                ]
            }
        }
        default:
            return state
    }
}