import { combineReducers } from 'redux';
import app from './reducers/app';
import auth from './reducers/auth';
import admin from './reducers/admin';
import sidebar from './reducers/sidebar';
import profileSettings from './reducers/profileSettings';
import resource from './reducers/resource';
import resourceType from './reducers/resourceType';
import clients from './reducers/clients';
import dashboard from './reducers/dashboard';
import business from './reducers/business';
import notification from './reducers/notification';
import reservation from './reducers/reservation';
import partner from './reducers/partner';
import facility from './reducers/facility';
import service from './reducers/service';
import facilityType from './reducers/facilityType';
import openingHours from './reducers/openingHours'
import permissions from './reducers/permissions';
import inavailabilityHour from './reducers/inavailabilityHour';
import payment from './reducers/payment';
import paymentType from './reducers/paymentType';
import creator from './reducers/creator';
import address from './reducers/address';
import image from './reducers/image';
import navbar from './reducers/navbar';
import role from './reducers/role';
import language from './reducers/language';
import reservationForm from './reducers/reservationForm';
import priceForm from './reducers/priceForm';
import galleryEditor from './reducers/galleryEditor';
import openingHourForm from './reducers/openingHourForm';
import contactRequest from './reducers/contactRequest';

export default combineReducers({
    address,
    admin,
    app,
    auth,
    business,
    clients,
    contactRequest,
    creator,
    dashboard,
    facility,
    facilityType,
    galleryEditor,
    image,
    inavailabilityHour,
    language,
    navbar,
    notification,
    openingHours,
    openingHourForm,
    partner,
    payment,
    paymentType,
    permissions,
    priceForm,
    profileSettings,
    reservation,
    reservationForm,
    resource,
    resourceType,
    role,
    service,
    sidebar,
});