import React from "react";
import {useDispatch, useSelector} from "react-redux";
import * as api from "../../../../core/api";
import {createAction, hasPermissionTo, toastError} from "../../../../core/utils/appUtil";
import schemas from "../../../../reducers/schemas";
import {normalize} from 'normalizr';
import PartnerEmailField from "../../partner/PartnerEmailField";
import PartnerFullnameField from "../../partner/PartnerFullnameField";
import PartnerPhoneField from "../../partner/PartnerPhoneField";
import {Row, Col} from 'reactstrap'
import {BiEnvelope} from 'react-icons/bi'
import PartnerEnabledField from "../../partner/PartnerEnabledField";
import PartnerVisibleField from "../../partner/PartnerVisibleField";
import {PARTNER_INVITATION_SEND, PARTNER_UPDATE_SUCCESS} from "../../../../reducers/partner";
import PartnerRoleField from "../../partner/PartnerRoleField";
import DisabledTextField from "../../../../core/form/DisabledTextField";
import {toastSuccess} from "../../../../core/utils/appUtil";
import handleError from "../../../../core/utils/errorUtil";
import Button from "../../../../core/buttons/Button";
import CardFormWithSkeleton from "../../../../core/form/CardFormWithSkeleton";
import {PARTNER_INVITE} from "../../../../constants/permissions";
import {getLoggedUser} from "../../../../selectors";
import moment from 'moment';

let PartnerDetailsForm = function ({partnerId}) {

    let partner = useSelector(state => state.partner.byId[partnerId])

    let loading = useSelector(state => state.partner.loadingDetails)

    let dispatch = useDispatch();

    let businessId = partner?.business_id

    let renderForm = (form) => <Row>
        <Col lg={{size: 6, offset: 3}}>
            <PartnerFullnameField control={form.control} loading={loading}/>
            <PartnerEmailField control={form.control} loading={loading}/>
            <PartnerPhoneField control={form.control} loading={loading}/>
            {(businessId && !partner?.master_account) ? <PartnerRoleField control={form.control} loading={loading} defaultValue={partner?.role_id} businessId={businessId}/> : null}
            {partner?.master_account ? <DisabledTextField text='Właściciel' loading={loading} label='form.partner.role'/> : null}
            <PartnerVisibleField control={form.control} loading={loading}/>
            <PartnerEnabledField control={form.control} loading={loading}/>
        </Col>
    </Row>

    let prepareData = (data) => {
        return {
            fullname: data.fullname,
            email: data.email,
            phone: data.phone,
            visible: data.visible,
            enabled: data.enabled,
            roles: [data.role_id]
        }
    }

    return <CardFormWithSkeleton
        loading={loading}
        renderForm={renderForm}
        skeleton={[
            [{lg: 6, elements: 4}, {lg: 6, elements: 4}]
        ]}
        data={{
            ...partner,
            role_id: partner?.roles?.length > 0 ? partner?.roles[0] : null
        }}
        prepareData={prepareData}
        title='Dane pracownika'
        getPromise={(id, data) => api.updatePartner(data, id, ['address'])}
        onSubmit={data => dispatch(createAction(PARTNER_UPDATE_SUCCESS, normalize(data, schemas.partnerSchema)))}/>

}
export default PartnerDetailsForm